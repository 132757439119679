import React from 'react'
import { Helmet } from 'react-helmet'
import { globalHistory } from '@reach/router'

interface Props {
  accessToken?: string
  setDeviceId: React.Dispatch<React.SetStateAction<string | null>>
  onReady: (ready: boolean) => void
}

export default function SpotifyPlayer ({ accessToken, setDeviceId, onReady }: Props) {
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    if (!accessToken) return
    window.onSpotifyWebPlaybackSDKReady = () => {
      window.spotifyPlayer = new window.Spotify.Player({
        name: 'Radio Radio Player',
        volume: 0.5,
        getOAuthToken: (cb) => { cb(accessToken) },
      })

      window.spotifyPlayer.addListener('ready', (data) => {
        setDeviceId(data?.device_id || null)
        onReady(true)
      })
      window.spotifyPlayer.addListener('not_ready', () => {
        setDeviceId(null)
        onReady(false)
      })
      // window.spotifyPlayer.addListener('initialization_error', ({ message }) => {
      //   console.error('initialization_error', message)
      // })
      // window.spotifyPlayer.addListener('authentication_error', ({ message }) => {
      //   console.error('authentication_error', message)
      // })
      // window.spotifyPlayer.addListener('account_error', ({ message }) => {
      //   console.error('account_error', message)
      // })

      window.spotifyPlayer.connect()
    }

    setIsLoaded(true)

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') window.spotifyPlayer.disconnect()
    })
  }, [accessToken, setDeviceId])

  return isLoaded && accessToken ? (
    <Helmet>
      <script src="https://sdk.scdn.co/spotify-player.js"></script>
    </Helmet>
  ) : null
}
