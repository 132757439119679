import React from 'react'

import { useSpotifyAuth } from './spotifyAuth'

export function useSpotify () {
  const auth = useSpotifyAuth()
  const [deviceId, setDeviceId] = React.useState<string | null>(null)

  const spotifyCall = React.useCallback(async (
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    path: string,
    body?: any,
    noResponse: boolean = false,
  ) => {
    try {
      const raw = await fetch(`https://api.spotify.com/v1/${path}`, {
        method,
        body: JSON.stringify(body),
        headers: {
          'Authorization': `Bearer ${auth.accessToken}`,
          'Content-Type': 'application/json',
        },
      })

      if (noResponse) return

      const data = await raw.json()

      if (data?.error) throw new Error(data?.error?.message)

      return data || {}
    } catch (error) {
      if ((error as Error).message.includes('access token')) {
        auth.reset()
        return {}
      } else {
        console.error(`Spotify call failed: ${method} ${path}`, error)
        return {}
      }
    }
  }, [auth])

  const getPlaylist = React.useCallback(async (playlistId) => {
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    const playlist = await spotifyCall('GET', `playlists/${playlistId}`)
    if (!playlist.uri) throw new Error('INVALID_PLAYLIST')
    return playlist
  }, [auth.accessToken, spotifyCall])

  const playTrackFromPlaylist = React.useCallback(async (trackIndex, playlistUri) => {
    if (!deviceId) throw new Error('MISSING_DEVICE')
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    // TODO: only run me/player call when player on website is idle
    await spotifyCall('PUT', 'me/player', {
      device_ids: [deviceId],
      play: true,
    }, true)
    await spotifyCall('PUT', 'me/player/play', {
      device_id: deviceId,
      context_uri: playlistUri,
      position_ms: 0,
      offset: {
        position: trackIndex
      },
    }, true)
  }, [auth.accessToken, deviceId, spotifyCall])

  const playTrackOrPlaylist = React.useCallback(async (uri: string) => {
    if (!deviceId) throw new Error('MISSING_DEVICE')
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    await spotifyCall('PUT', 'me/player', {
      device_ids: [deviceId],
      play: true,
    }, true)
    await spotifyCall('PUT', 'me/player/play', {
      device_id: deviceId,
      context_uri: uri,
    }, true)
  }, [auth.accessToken, deviceId, spotifyCall])

  const getSavedTracks = React.useCallback(async () => {
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    const tracks: Array<{ track: Spotify.Track }> = [];
    const initial = await spotifyCall('GET', 'me/tracks?limit=50')
    tracks.push(...initial.items)
    for (let i = 50; i < initial.total; i += 50) {
      await new Promise((resolve) => setTimeout(resolve, 1000)) // prevent spßamming Spotify's API
      const result = await spotifyCall('GET', 'me/tracks?limit=50')
      tracks.push(...result.items)
    }
    return tracks.map(({ track }) => track.id!)
  }, [auth.accessToken, spotifyCall])

  const saveTrack = React.useCallback(async (id: string) => {
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    await spotifyCall('PUT', 'me/tracks', { ids: [id] }, true)
  }, [auth.accessToken, spotifyCall])

  const unsaveTrack = React.useCallback(async (id: string) => {
    if (!auth.accessToken) throw new Error('MISSING_CREDENTIALS')
    await spotifyCall('DELETE', 'me/tracks', { ids: [id] }, true)
  }, [auth.accessToken, spotifyCall])

  return {
    ...auth,
    setDeviceId,
    getPlaylist,
    playTrackFromPlaylist,
    playTrackOrPlaylist,
    getSavedTracks,
    saveTrack,
    unsaveTrack,
  }
}
