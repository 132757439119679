/** @jsx jsx */
import React from 'react'
import { jsx, Box, Grid } from 'theme-ui'
import { StyledLink } from '@components/Links'

import PageHeader from './PageHeader'
import WeTransferLogo from './WeTransferLogo'
import SpotifyControls from './SpotifyControls'
import SpotifyPlayer from '../utils/spotifyPlayer'
import { useSpotify } from '../utils/spotify'

import localCoverArt from '../images/2021.jpeg'

interface Props {
  path: string
  data: {
    id: string
    title: string
    image: string
  }
}

export default function PlaylistOverview({ data, path = '/playlists' }: Props) {
  const [playlist, setPlaylist] =
    React.useState<SpotifyApi.PlaylistObjectFull | null>(null)
  const [playerReady, setPlayerReady] = React.useState(false)
  const {
    accessToken,
    connect,
    setDeviceId,
    getPlaylist,
    playTrackFromPlaylist,
    getSavedTracks,
    saveTrack,
    unsaveTrack,
  } = useSpotify()

  React.useEffect(() => {
    if (!accessToken) return
    getPlaylist(data.id)
      .then((playlist) => setPlaylist(playlist))
      .catch((error) => {
        console.error('getPlaylist', error)
        // TODO: show error
      })
  }, [accessToken])

  const play = React.useCallback(
    async (trackIndex) => {
      try {
        await playTrackFromPlaylist(trackIndex, playlist?.uri)
      } catch (error) {
        console.error('playTrackFromPlaylist', error)
        // TODO: show error
      }
    },
    [playlist, playTrackFromPlaylist]
  )

  const description = React.useMemo(() => {
    if (!playlist?.description) return ''
    const doc = new DOMParser().parseFromString(playlist.description, 'text/html')
    return doc.documentElement.textContent
  }, [playlist?.description])

  const onPlayerReady = React.useCallback((ready: boolean) => {
    setPlayerReady(ready)
  }, [setPlayerReady])

  return (
    <>
      <div className="playlist-page">
        <PageHeader
          spaceBetween
          padding
          title={data.title}
          extra={
            <div sx={{ float: 'right' }}>
              <WeTransferLogo />
              <StyledLink to="/playlists">See all playlists</StyledLink>
            </div>
          }
        />
        <SpotifyPlayer accessToken={accessToken} setDeviceId={setDeviceId} onReady={onPlayerReady} />
        <Grid
          sx={{
            gridGap: 0,
            borderTop: '1px solid black',
            gridTemplateRows: [`auto`, `auto`, `auto`, `85vh auto`],
            gridTemplateColumns: ['100%', '100%', '100%', '50% 50%'],
          }}
        >
          <Box>
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                borderRight: playlist?.tracks ? 'none' : '1px solid #000',

                '@media only screen and (max-width: 1024px)': {
                  height: '90vh',
                  borderBottom: '1px solid black',
                },
              }}
            >
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '65%',
                  background:
                    'linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%), linear-gradient(to top right,rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%)',
                }}
              >
                <img
                  src={data?.image || localCoverArt}
                  sx={{
                    width: '330px',
                    height: '330px',
                  }}
                />
              </div>
              <span
                sx={{
                  borderTop: '1px solid black',
                  padding: '10px 15px',
                }}
              >
                {description}
              </span>
            </div>
          </Box>
          <Box>
            {accessToken || playlist ? (
              playlist?.tracks ? (
                <>
                  <table
                    sx={{
                      flex: 1,
                      width: '100%',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <tbody>
                      {playlist.tracks.items.map(({ track }, index) => (
                        <tr
                          key={track.uri}
                          onClick={() => play(index)}
                          sx={{
                            lineHeight: 0,
                            maxHeight: '80px',
                            borderBottom: '1px solid #000',
                            '&:hover': {
                              backgroundColor: '#000',
                              borderBottom: '1px solid #DCDCDC',
                              cursor: 'pointer',
                              td: {
                                padding: '10px',
                                borderColor: '#DCDCDC',

                                span: {
                                  lineHeight: 1.3,
                                  color: '#DCDCDC',
                                },
                              },
                            },
                            td: {
                              padding: '10px',
                              borderBottom: '1px solid black',
                              borderLeft: '1px solid #000',
                              span: {
                                lineHeight: 1.3,
                              },
                            },
                          }}
                        >
                          <td
                            sx={{
                              width: '80px',
                            }}
                          >
                            <img
                              src={track.album.images[0].url}
                              sx={{
                                minWidth: '60px',
                                minHeight: '60px',
                                maxWidth: '60px',
                                maxHeight: '60px',
                                objectFit: 'cover',
                                marginBottom: 0,
                                border: '1px solid #000',
                              }}
                            />
                          </td>
                          <td>
                            <span>
                              {track.artists
                                .map((artist) => artist.name)
                                .join(', ')}
                            </span>
                          </td>
                          <td>
                            <span
                              sx={{
                                minWidth: '373px',
                                maxWidth: '373px',
                              }}
                            >
                              {track.name}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div sx={{ height: '91px' }} />
                </>
              ) : (
                <div
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '40vh',
                  }}
                >
                  {/* TODO: add loading indicator */}
                  <span sx={{ fontSize: '20px' }}>
                    Loading playlist ...
                  </span>
                </div>
              )
            ) : (
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '40vh',
                }}
              >
                <button
                  sx={{
                    border: '1px solid #000',
                    backgroundColor: '#DCDCDC',
                    padding: '10px 40px',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                  }}
                  onClick={() => connect(path)}
                >
                  Connect to Spotify Premium
                </button>
              </div>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: '3rem 3rem calc(3rem + 92px) 3rem',
              borderTop: '1px solid #000',
              borderRight: playlist?.tracks ? 'none' : '1px solid #000',
            }}
          >
            ↓{' '}
            <StyledLink sx={{}} to="/playlists">
              See all playlists
            </StyledLink>
          </Box>
        </Grid>
        {playerReady && (
          <SpotifyControls
            playlist={playlist}
            accessToken={accessToken}
            playTrackFromPlaylist={playTrackFromPlaylist}
            getSavedTracks={getSavedTracks}
            saveTrack={saveTrack}
            unsaveTrack={unsaveTrack}
          />
        )}
      </div>
    </>
  )
}
