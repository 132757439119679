import React from 'react'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PlaylistOverview from '../components/PlaylistOverview'

export default function PlaylistsPage({ location, pageContext: { playlist } }) {
  return (
    <Layout hideFooter>
      <SEO title={`${playlist?.title}`} />
      <PlaylistOverview data={playlist || {}} path={location.pathname} />
    </Layout>
  )
}
