/** @jsx jsx */
import React from 'react'
import MediaQuery from 'react-responsive'
import { jsx } from 'theme-ui'

interface Details {
  nextTracks: Spotify.Track[]
  prevTracks: Spotify.Track[]
  duration: number
  progress: number
  playing: boolean
  albumArt: string | null
  artists: string
  title: string
  id: string | null
}

interface Props {
  playlist: SpotifyApi.PlaylistObjectFull | null
  accessToken?: string
  playTrackFromPlaylist: (trackIndex: any, playlistUri: any) => Promise<void>
  getSavedTracks: () => Promise<string[]>
  saveTrack: (id: string) => Promise<void>
  unsaveTrack: (id: string) => Promise<void>
}

export default function SpotifyControls({
  playlist,
  accessToken,
  playTrackFromPlaylist,
  getSavedTracks,
  saveTrack,
  unsaveTrack,
}: Props) {
  const [updateInterval, setUpdateInterval] =
    React.useState<NodeJS.Timeout | null>(null)
  const [savedTracks, setSavedTracks] = React.useState<string[] | null>(null)
  const [details, setDetails] = React.useState<Details>({
    nextTracks: [],
    prevTracks: [],
    duration: 0,
    progress: 0,
    playing: false,
    albumArt: null,
    artists: '',
    title: '',
    id: null,
  })

  React.useEffect(() => {
    if (updateInterval) clearInterval(updateInterval)
    setUpdateInterval(
      setInterval(async () => {
        try {
          if (window.spotifyPlayer && accessToken) {
            const state = await window.spotifyPlayer?.getCurrentState()
            if (!state) return null
            setDetails({
              nextTracks: state.track_window.next_tracks,
              prevTracks: state.track_window.previous_tracks,
              duration: state.duration,
              progress: (state.position / state.duration) * 100,
              playing: !state.paused,
              albumArt:
                state.track_window.current_track.album.images[0]?.url || null,
              artists: state.track_window.current_track.artists
                .map((artist) => artist.name)
                .join(', '),
              title: state.track_window.current_track.name,
              id: state.track_window.current_track.id,
            })
          }
        } catch (ex) {
          console.error(ex)
        }
      }, 2000)
    )
  }, [accessToken])

  const previousTrack = React.useCallback(async () => {
    if (window.spotifyPlayer) {
      setDetails((old) => {
        const tempOldTracks = [...old.prevTracks]
        const oldPrevTrack = tempOldTracks.pop()
        const prevTrack = playlist?.tracks.items.find(
          ({ track }) => track.id === oldPrevTrack?.id
        )
        return {
          ...old,
          progress: 0,
          playing: true,
          ...(prevTrack
            ? {
                prevTracks: tempOldTracks,
                albumArt: prevTrack.track.album.images[0]?.url || null,
                artists: prevTrack.track.artists
                  .map((artist) => artist.name)
                  .join(', '),
                title: prevTrack.track.name,
                id: prevTrack.track.id,
              }
            : {}),
        }
      })
      setDetails((old) => ({ ...old, progress: 0, playing: true }))
      await window.spotifyPlayer.previousTrack()
    }
  }, [playlist])
  const playPause = React.useCallback(async () => {
    if (window.spotifyPlayer) {
      if (playlist && !details.title) {
        setDetails((old) => ({ ...old, playing: true }))
        try {
          await playTrackFromPlaylist(0, playlist.uri)
        } catch (ex) {
          console.error(ex)
        }
      } else {
        setDetails((old) => ({ ...old, playing: !old.playing }))
        await window.spotifyPlayer.togglePlay()
      }
    }
  }, [details])
  const nextTrack = React.useCallback(async () => {
    if (window.spotifyPlayer) {
      setDetails((old) => {
        const [oldNextTrack, ...oldNextTracks] = old.nextTracks
        const nextTrack = playlist?.tracks.items.find(
          ({ track }) => track.id === oldNextTrack?.id
        )
        return {
          ...old,
          progress: 0,
          playing: true,
          ...(nextTrack
            ? {
                nextTracks: oldNextTracks,
                albumArt: nextTrack.track.album.images[0]?.url || null,
                artists: nextTrack.track.artists
                  .map((artist) => artist.name)
                  .join(', '),
                title: nextTrack.track.name,
                id: nextTrack.track.id,
              }
            : {}),
        }
      })
      await window.spotifyPlayer.nextTrack()
    }
  }, [playlist])
  const seek = React.useCallback(
    async (e) => {
      if (window.spotifyPlayer) {
        const positionX = (e.pageX / window.innerWidth) * 100
        setDetails((old) => ({ ...old, progress: positionX }))
        await window.spotifyPlayer.seek((details.duration / 100) * positionX)
      }
    },
    [details.duration]
  )

  const saveCurrentTrack = React.useCallback(() => {
    if (!details.id) return
    saveTrack(details.id)
    setSavedTracks((old) => [...old!, details.id!])
  }, [details.id, saveTrack, setSavedTracks])

  const unsaveCurrentTrack = React.useCallback(() => {
    if (!details.id) return
    unsaveTrack(details.id)
    setSavedTracks((old) => old!.filter((id) => id !== details.id))
  }, [details.id, unsaveTrack, setSavedTracks])

  React.useEffect(() => {
    (async () => {
      if (accessToken && savedTracks === null) {
        const ids = await getSavedTracks()
        setSavedTracks(ids)
      }
    })()
  }, [savedTracks, accessToken])

  const saved = React.useMemo(
    () => !!details.id && (savedTracks?.includes(details.id) || false),
    [details.id, savedTracks],
  );

  return (
    <div
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 998,
        maxHeight: '92px',
        backgroundColor: '#000',
        borderTop: '1px solid #000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div
        sx={{
          height: '12px',
          backgroundColor: '#DCDCDC',
          display: 'flex',
        }}
        onClick={seek}
      >
        <div
          sx={{
            height: '12px',
            width: `${details.progress}%`,
            backgroundColor: '#000',
            '&:after': {
              content: '""',
              height: '12px',
              width: '12px',
              border: '1px solid black',
              backgroundColor: '#fff',
              position: 'absolute',
              left: `calc(${details.progress}% - 6px)`,
            },
          }}
        />
        <div />
      </div>
      <div
        sx={{
          height: '80px',
          display: 'flex',
          flexDirection: 'row',
          borderTop: '1px solid #DCDCDC',
        }}
      >
        <div
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <button
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 25px',
              border: 'none',
              borderRight: '1px solid #DCDCDC',
              background: 'none',
              outline: 'none',
              cursor: details.id ? 'pointer' : 'default',
            }}
            onClick={details.id && savedTracks !== null ? (saved ? unsaveCurrentTrack : saveCurrentTrack) : undefined}
          >
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke={details.id && savedTracks !== null ? '#DCDCDC' : 'rgba(220, 220, 220, 0.6)'}
                fill={saved ? '#DCDCDC' : 'none'}
                d="M22.8615 3.48577L22.8638 3.48989C23.4977 4.65301 23.6388 6.14728 23.3721 7.59704C23.1046 9.0506 22.439 10.3935 21.5432 11.2471L21.5432 11.247L21.5368 11.2533L12.0013 20.668L2.46571 11.2533L2.46577 11.2532L2.4591 11.2469C1.56215 10.3933 0.895873 9.05013 0.62813 7.59645C0.36063 6.14408 0.502755 4.64729 1.13934 3.48407C1.47676 2.8787 2.71625 1.47259 4.53861 1.01678C6.29669 0.577045 8.74669 0.988558 11.624 4.26888L12.0092 4.70801L12.3837 4.2597C15.1138 0.991039 17.5599 0.575754 19.3565 1.01727C20.2802 1.24429 21.0738 1.70711 21.6852 2.20082C22.3024 2.69919 22.7054 3.20565 22.8615 3.48577Z"
              />
            </svg>
          </button>
          {details?.albumArt &&
          <img
            src={details.albumArt ?? ''}
            sx={{
              minWidth: '60px',
              minHeight: '60px',
              maxWidth: '60px',
              maxHeight: '60px',
              objectFit: 'cover',
              marginLeft: '10px',
              marginRight: '15px',
              border: '1px solid #fff',
            }}
          />}
          <MediaQuery minWidth={999}>
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',

                '> span': {
                  fontSize: '20px',
                  color: '#DCDCDC',
                },
              }}
            >
              <span>{details.title}</span>
              <span>{details.artists}</span>
            </div>
          </MediaQuery>
        </div>
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            height: '100%',
            '> button': {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '80px',
              border: 'none',
              outline: 'none',
              background: 'none',
              borderLeft: '1px solid #DCDCDC',
              cursor: 'pointer',

              ':last-child': {
                borderRight: '1px solid #DCDCDC',
              },
            },
          }}
        >
          <button onClick={previousTrack}>
            <svg
              width="25"
              height="17"
              viewBox="0 0 25 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9648 0.40918L6.95072 4.39885L3.43323e-05 8.45302L6.95072 12.4436L13.9648 16.4977V0.40918Z"
                fill="#DCDCDC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8945 0.40918L17.8804 4.39885L10.9297 8.45302L17.8804 12.4436L24.8945 16.4977V0.40918Z"
                fill="#DCDCDC"
              />
              <rect
                width="3.42884"
                height="16.0727"
                transform="matrix(-1 0 0 1 3.46387 0.492188)"
                fill="#DCDCDC"
              />
            </svg>
          </button>
          <button onClick={playPause}>
            {details.playing ? (
              <svg
                width="18"
                height="23"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 0V10.5M1 0V10.5"
                  stroke="#DCDCDC"
                  strokeWidth="3"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="23"
                viewBox="0 0 18 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 0.370605L9.04088 5.82621L18 11.37L9.04088 16.8268L0 22.3706V0.370605Z"
                  fill="#DCDCDC"
                />
              </svg>
            )}
          </button>
          <button onClick={nextTrack}>
            <svg
              width="25"
              height="17"
              viewBox="0 0 25 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9297 0.40918L17.9438 4.39885L24.8945 8.45302L17.9438 12.4436L10.9297 16.4977V0.40918Z"
                fill="#DCDCDC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0.40918L7.01412 4.39885L13.9648 8.45302L7.01412 12.4436L0 16.4977V0.40918Z"
                fill="#DCDCDC"
              />
              <rect
                x="21.4307"
                y="0.492188"
                width="3.42884"
                height="16.0727"
                fill="#DCDCDC"
              />
            </svg>
          </button>
        </div>
        <div
          sx={{
            flex: 1,
          }}
        >
          {/* volume controls */}
        </div>
      </div>
    </div>
  )
}
