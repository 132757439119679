import React from 'react'
import { navigate } from 'gatsby'

const CLIENT_ID = process.env.SPOTIFY_CLIENT_ID
const CLIENT_SECRET = process.env.SPOTIFY_CLIENT_SECRET

const isBrowser = typeof window !== `undefined`

const REDIRECT_URI = isBrowser ? `${window.location.protocol}//${window.location.host}/spotify` : process.env.NODE_ENV === 'development'
? 'http://localhost:8000/spotify'
: 'https://radioradio.radio/spotify'
const SCOPES = encodeURIComponent([
  'user-library-read',          // Read saved tracks
  'user-library-modify',        // Add/remove saved track
  'streaming',                  // Web Playback SDK
  'user-read-playback-state',   // Player status
  'user-modify-playback-state'  // Playing songs
].join(' '))

interface SpotifyAuthState {
  redirect?: string
  accessToken?: string
  refreshToken?: string
  refreshAt?: number
}

export function useSpotifyAuth () {
  const [refreshTimeout, setRefreshTimeout] = React.useState<NodeJS.Timeout | null>(null)
  const [state, setState] = React.useState<SpotifyAuthState>({})

  const update = React.useCallback((partialState) => {
    let newState = {}

    setState((prevState) => {
      newState = { ...prevState, ...partialState }
      return newState
    })

    localStorage.setItem('spotify', JSON.stringify(newState))

    // redirecting directly after setting localStorage can cause issues
    return new Promise((resolve) => setTimeout(resolve, 100))
  }, [setState])

  const reset = React.useCallback(() => {
    setState({})
    localStorage.removeItem('spotify')
  }, [setState])

  const connect = React.useCallback(async (redirect) => {
    const data = new URLSearchParams({
      response_type: 'code',
      client_id: CLIENT_ID!,
      scope: SCOPES,
      redirect_uri: REDIRECT_URI
    }).toString()

    await update({ redirect })
    window.location.href = `https://accounts.spotify.com/authorize?${data}`
  }, [update])

  const connectCallback = React.useCallback(async (code) => {
    const raw = await fetch(
      'https://accounts.spotify.com/api/token?' +
      new URLSearchParams({
        code,
        grant_type: 'authorization_code',
        redirect_uri: REDIRECT_URI
      }).toString(),
      {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )

    const data = await raw.json()
    if (!data?.access_token) return console.error('spotify response error', data)

    let url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.delete('code')
    url.search = params.toString()
    window.history.replaceState('', '', url.toString())

    await update({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      refreshAt: new Date().getTime() + ((data.expires_in - 300) * 1000)
    })

    // FIXME: state.redirect is null sometimes?
    navigate(state.redirect || '/playlists')
  }, [state.redirect, update])

  const refreshToken = React.useCallback(async () => {
    try {
      if (!state.refreshToken || !state.refreshAt) {
        // if (state.accessToken) reset()
        return
      }

      // if ((state.refreshAt - new Date().getTime()) > 1000000) {
      //   if (refreshTimeout) clearTimeout(refreshTimeout)
      //   setRefreshTimeout(setTimeout(() => refreshToken, 300000))
      //   return
      // }

      const raw = await fetch(
        'https://accounts.spotify.com/api/token?' +
        new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: state.refreshToken
        }).toString(),
        {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      const data = await raw.json()
      if (!data?.access_token) return console.error('Spotify response error: POST api/token', data)

      await update({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        refreshAt: new Date().getTime() + ((data.expires_in - 300) * 1000)
      })

      if (refreshTimeout) clearTimeout(refreshTimeout)
      setRefreshTimeout(setTimeout(() => refreshToken(), 300000))
    } catch (error) {
      if ((error as Error).message.includes('access token')) {
        reset()
        return {}
      } else {
        console.error('Spotify response error: POST api/token', error)
        return {}
      }
    }
  }, [refreshTimeout, setRefreshTimeout, update, reset, state])

  React.useEffect(() => {
    // set initial state again because of SSR
    const initialState = JSON.parse(localStorage.getItem('spotify') || '{}')
    setState(initialState)
    if (initialState.accessToken) refreshToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    reset,
    update,
    connect,
    connectCallback,
    accessToken: state.accessToken,
  }
}
